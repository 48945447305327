<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <div class="mb20">
        <a-button  type="primary" v-has:msgPushAdd="()=> {visible=true;type='add'}">
          <PlusOutlined />
          添加消息推送内容
        </a-button>
      </div>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
        :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="触发项目" name='keyword' >
          <a-input v-model:value="search.keyword"  style="width:250px"  placeholder="请输入触发项目进行搜索"></a-input>
        </a-form-item>
        <a-form-item label="消息类型" name='level'>
          <a-select v-model:value="search.type" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="item.type" v-for='item in typeLits' :key='item'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="更新时间" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data'  class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:sms_content="{ record }">
          <div style="max-width: 500px;">{{record.sms_content || '-'}}</div>
        </template>
        <template v-slot:message_content="{ record }">
          <div style="max-width: 500px;">{{record.message_content || '-'}}</div>
        </template>
        <template v-slot:status="{ record }">
          <a-switch :checkedValue="1" :unCheckedValue="0" v-model:checked="record.status" checked-children="启用" un-checked-children="禁用" v-authAction:msgPushStatus="()=>switchChange(record)" />
        </template>
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:msgPushEdit="()=> {visible=true;type='edit';formData=record}" href="javascript:;">编辑</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:msgPushDel="()=> listDel(record.id)" href="javascript:;">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
        :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
        @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
    <a-modal v-model:visible="visible" :title="type == 'add' ? '添加消息推送模板':'编辑消息推送模板'" :width='1000' :footer="null" destroyOnClose centered>
      <msgPushForm :typeList='typeLits' :formData='formData' :type='type' @onsubmit='onsubmit'></msgPushForm>
    </a-modal>
  </a-layout>
</template>
<script>
  import { DownOutlined,PlusOutlined } from '@ant-design/icons-vue';
  import { ref, onMounted, reactive, toRefs, inject } from 'vue';
  import { message } from 'ant-design-vue'
  import { $iscode } from '@/utils/app'
  import { getMessPush, getMessPushType, getMessPushMore,messPushStatus,messPushDel } from "@/api/brand";
  import msgPushForm from './components/msgPushForm.vue'
  // 初始化默认筛选项数值
  let defSearch = {
    keyword:'',
    start_time: '',
    end_time: '',
    page: 1,
    limit: 10,
    status: '',   //状态：1.启用0.禁用
    type:''   
  }
  export default {
    components: { DownOutlined,PlusOutlined,msgPushForm },
    setup(props) {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([])
      const state = reactive({
        listLoading: false,
        visible: false,
        total: 1,
        time: [],
        type:'add',
        typeLits:[],
        formData:{}
      })
      let columns = [{
        title: '消息类型',
        dataIndex: 'type_text',
        key: 'type_text',
      },
      {
        title: '触发项目',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '发送时间',
        dataIndex: 'condition',
        key: 'condition',
      },
      {
        title: '手机短信',
        dataIndex: 'sms_content',
        key: 'sms_content',
        slots: { customRender: 'sms_content' },
      },
      {
        title: '用户消息中心模板',
        dataIndex: 'message_content',
        key: 'message_content',
        slots: { customRender: 'message_content' },
      },
      {
        title: '是否应用',
        dataIndex: 'status',
        key: 'status',
        slots: { customRender: 'status' },
      },
      {
        title: '更新时间',
        dataIndex: 'update_time',
        key: 'update_time',
      },
      {
        title: '操作',
        key: 'action',
        width:100,
        fixed: 'right',
        slots: { customRender: 'action' },
      },
      ]
      const $Modal = inject('$Modal')
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 }
        initData(search.value);
      }
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      }
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      }
      // 获取列表
      const initData = async (values) => {
        state.listLoading = true;
        try {
          let res = await getMessPush(values).then(res => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.message)
          }
        } catch (e) {
          state.listLoading = false;
        }
      }
      //获取类型
      const getType = async() =>{
        let res = await getMessPushType().then(res => res.data);
        if ($iscode(res)) {
          state.typeLits = res.data;
        } else {
          message.error(res.msg);
        }
      }
      // 删除
      const listDel = (e) => {
        $Modal.confirm({
          title: '删除消息订阅模板',
          content: '您确定要删除该删除消息订阅模板？',
          centered:true,
          onOk: async () => {
            let res = await messPushDel(e).then(res => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData(search.value);
            } else {
              message.error(res.msg);
            }
          }
        })
      }
      //状态修改
      const switchChange = async(e) =>{
        console.log(e)
        var status = e.status
        let res = await messPushStatus({id:e.id,status:status}).then(res => res.data);
        if ($iscode(res)) {
          message.success(res.msg);
        }else {
          message.error(res.msg);
        }
      }
      // 时间筛选
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.start_time = e[0]
          search.value.end_time = e[1]
        } else {
          search.value.start_time = ''
          search.value.end_time = ''
        }
      }
      // 提交
      const onsubmit = () =>{
        state.visible = false
        initData(search.value);
      }
      onMounted(() => {
        getType();
        initData(search.value);
      })
      return {
        ...toRefs(state),
        search,
        data,
        columns,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        onChange,
        listDel,
        onsubmit,
        switchChange
      }
    }
  }
</script>